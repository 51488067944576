import {
  type UseQueryOptions,
  useQuery,
  useQueryClient,
} from "@tanstack/vue-query";
import type { Get } from "type-fest";
import type { paths } from "@/schemas/contool";
import type {
  ErrorResponse,
  FilterKeys,
  HttpMethod,
  MediaType,
  ResponseObjectMap,
  SuccessResponse,
} from "openapi-typescript-helpers";
import type { FetchError } from "ofetch";

export const USE_CONTOOL_QUERY_KEY = "useContool";
export const BASE_URL = "/api/contool";

export const generateContoolQueryKey = (
  path: string,
  method: string,
  query?: unknown,
) => [USE_CONTOOL_QUERY_KEY, path, method, query];

export async function contoolQueryFn<T>(path: string, method: HttpMethod) {
  const req = await $fetch(path, {
    baseURL: BASE_URL,
    method,
  });

  return req as T;
}

type DistributiveOmit<T, K extends PropertyKey> = T extends any
  ? Omit<T, K>
  : never;

interface useContoolFetchOptions<
  RequestOperation,
  RequestSuccessData,
  RequestErrorData,
> {
  skip?: boolean;

  params?: MaybeRefOrGetter<
    Get<RequestOperation, "parameters.path", { strict: true }>
  >;
  query?: MaybeRefOrGetter<
    Get<RequestOperation, "parameters.query", { strict: true }>
  >;
  queryOptions?: DistributiveOmit<
    UseQueryOptions<RequestSuccessData, RequestErrorData, RequestSuccessData>,
    "queryKey" | "queryFn" | "select"
  >;
}

export function useContool<
  RequestPath extends keyof paths,
  RequestMethod extends keyof paths[RequestPath],
  RequestOperation extends paths[RequestPath][RequestMethod],
  RequestSuccessData extends FilterKeys<
    SuccessResponse<ResponseObjectMap<RequestOperation>>,
    MediaType
  >,
  RequestErrorData extends FetchError<
    FilterKeys<ErrorResponse<ResponseObjectMap<RequestOperation>>, MediaType>
  >,
>(
  path: MaybeRefOrGetter<RequestPath>,
  method: MaybeRefOrGetter<RequestMethod>,
  options?: useContoolFetchOptions<
    RequestOperation,
    RequestSuccessData,
    RequestErrorData
  >,
) {
  const pathValue = toValue(path);
  const methodValue = toValue(method);

  const pathWithoutQuery = computed(() => {
    let pathWithoutQuery: string = pathValue;

    if (options && toValue(options.params) != null) {
      // @ts-expect-error - Mabok ini TS
      const entries = Object.entries(toValue(options?.params));
      for (const [key, value] of entries) {
        pathWithoutQuery = pathWithoutQuery.replaceAll(
          `{${key}}`,
          encodeURIComponent(value as string),
        );
      }
    }

    return pathWithoutQuery;
  });

  const urlSearchParams = computed(() => {
    const urlSearchParams = new URLSearchParams();
    if (options && toValue(options.query) != null) {
      // @ts-expect-error - Mabok ini TS
      const entries = Object.entries(toValue(options?.query));
      for (const [key, value] of entries) {
        urlSearchParams.append(key, value as string);
      }
    }

    return urlSearchParams;
  });

  const finalPath = computed(
    () => `${pathWithoutQuery.value}?${urlSearchParams.value.toString()}`,
  );

  const useQueryOptions = computed(() => {
    return {
      enabled: !options?.skip,
      queryKey: generateContoolQueryKey(
        pathWithoutQuery.value,
        methodValue as HttpMethod,
        toValue(options?.query),
      ),

      queryFn: () =>
        contoolQueryFn<RequestSuccessData>(
          finalPath.value,
          methodValue as HttpMethod,
        ),

      staleTime: 1000 * 60 * 60,

      ...options?.queryOptions,
    };
  });

  return useQuery<RequestSuccessData, RequestErrorData, RequestSuccessData>(
    useQueryOptions,
  );
}

// Uncomment for testing purposes
// const a = reactive(
//   useContool("/v1/event/{id}/terms", "get", {
//     query: { language: "" },
//     params: { id: 2 },
//     queryOptions: {},
//   }),
// );

export function usePrefetchContool<
  RequestPath extends keyof paths,
  RequestMethod extends keyof paths[RequestPath],
  RequestOperation extends paths[RequestPath][RequestMethod],
  RequestSuccessData extends FilterKeys<
    SuccessResponse<ResponseObjectMap<RequestOperation>>,
    MediaType
  >,
  RequestErrorData extends FilterKeys<
    ErrorResponse<ResponseObjectMap<RequestOperation>>,
    MediaType
  >,
>(
  path: MaybeRefOrGetter<RequestPath>,
  method: MaybeRefOrGetter<RequestMethod>,
  options?: useContoolFetchOptions<
    RequestOperation,
    RequestSuccessData,
    RequestErrorData
  >,
) {
  const queryClient = useQueryClient();

  const pathValue = toValue(path);

  const pathWithoutQuery = computed(() => {
    let pathWithoutQuery: string = pathValue;

    if (options && toValue(options.params) != null) {
      // @ts-expect-error - Mabok ini TS
      const entries = Object.entries(toValue(options?.params));
      for (const [key, value] of entries) {
        pathWithoutQuery = pathWithoutQuery.replaceAll(
          `{${key}}`,
          encodeURIComponent(value as string),
        );
      }
    }

    return pathWithoutQuery;
  });

  const urlSearchParams = computed(() => {
    const urlSearchParams = new URLSearchParams();
    if (options && toValue(options.query) != null) {
      // @ts-expect-error - Mabok ini TS
      const entries = Object.entries(toValue(options?.query));
      for (const [key, value] of entries) {
        urlSearchParams.append(key, value as string);
      }
    }

    return urlSearchParams;
  });

  const finalPath = computed(
    () => `${pathWithoutQuery.value}?${urlSearchParams.value.toString()}`,
  );

  const queryOptions = computed(() => {
    return {
      queryKey: generateContoolQueryKey(
        pathWithoutQuery.value,
        method as string,
        options?.query,
      ),
      queryFn: () => contoolQueryFn(finalPath.value, method as HttpMethod),
    };
  });

  // It's fine to ignore the promise here as its a prefetch
  return queryClient.prefetchQuery(queryOptions);
}
